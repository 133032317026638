import React from 'react'
import Layout from '../../components/Global/Layout'
import { graphql } from 'gatsby'
import GetInTouch from '../../components/GetInTouch'

const locale = 'en-US'

export const query = graphql`
  {
    allContentfulContactPage {
      edges {
        node {
          title
          methodTitles
          button1
          button2
          bodyText1
          bodyText2
          bodyText3
          node_locale
        }
      }
    }
  }
`

const GetInTouchPage = (props) => {
  const faqData = props.data?.allContentfulContactPage.edges?.filter(
    (node) => node.node.node_locale === locale
  )[0]?.node

  return (
    <Layout locale={locale} url='/get-in-touch' title='Get in touch'>
      <GetInTouch locale={locale} pageContent={faqData} />
    </Layout>
  )
}

export default GetInTouchPage
